import React from "react";
import "../components/style.scss";
import "../components/columns.scss";
import Helmet from "react-helmet";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
const NotFoundPage = () => (
  <div>
    <Helmet>
      <title>¡Muchas gracias! | Battersea</title>
      <meta
        name="description"
        content="En nuestra Agencia somos especialistas en posicionar tu página mediante SEO o SEM. ¡No dudes en contactarnos para maximizar tus ganancias!"
      ></meta>
      <meta name="robots" content="index,follow"></meta>
    </Helmet>
    <Navbar />
    <section className="hero is-fullheight is-light">
      <div className="hero-body">
        <div className="container">
          <div className="columns has-text-centered">
            <div className="column"></div>
            <div className="column is-6">
              <p className="subtitle is-3 is-size-5-mobile has-text-white has-text-weight-bold is-uppercase">
                ¡Una disculpa! :'( <br />
                La página que estabas buscando no está disponible.
              </p>
            </div>

            <div className="column"></div>
          </div>
          <div className="columns has-text-centered">
            <div className="column"></div>
            <div className="column is-3">
              <a
                href="/"
                className="button is-danger is-outlined is-rounded is-inverted"
              >
                Regresa al inicio
              </a>
            </div>
            
            <div className="column"></div>
          </div>
        </div>
      </div>
      <div className="hero-foot"></div>
    </section>

    <Footer />
  </div>
);

export default NotFoundPage;
